import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { useHourlyData } from 'hooks/useFetch';
import { CardContent, Card as MuiCard, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import { ThemeProps } from '../../../types/theme';
import { useAtomValue } from 'jotai';
import { fromDaySummaryAtom, greenBondIdAtom, toDaySummaryAtom } from '../../../jotai/Atoms';
import { getGreenBondConfig } from 'greenBondConfig';

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
    height: 300px;
    width: 100%;
`;

const summaryHourlyChart = ({ theme }: ThemeProps) => {
    const from = useAtomValue(fromDaySummaryAtom);
    const to = useAtomValue(toDaySummaryAtom);
    const greenBondId = useAtomValue(greenBondIdAtom);
    const config = getGreenBondConfig(greenBondId);
    const fetchData = useHourlyData(greenBondId, from, to);
    // 表示項目がどのようなtypeであろうが、データは下記の名前で取得できる
    const dateTimes: string[] = fetchData['data']?.['powerDateTime'];
    const firstValues: number[] = fetchData['data']?.['GeneratedGreenEnergy'];
    const secondValues: number[] = fetchData['data']?.['AvoidedCO2'];

    const title = `${config.firstValueLabel} / ${config.secondValueLabel} (24時間以内)`;
    const yLeftLabel = config.firstValueLabel;
    const yRightLabel = config.secondValueLabel;
    const yLeftUnit = config.firstValueUnit;
    const yRightUnit = config.secondValueUnit;

    const data = {
        labels: dateTimes,
        datasets: [
            {
                label: yLeftLabel,
                fill: true,
                backgroundColor: 'transparent',
                borderColor: theme.palette.secondary.main,
                data: firstValues,
                yAxisID: 'y_left',
            },
            {
                label: yRightLabel,
                fill: true,
                backgroundColor: 'transparent',
                borderColor: theme.palette.grey[300],
                data: secondValues,
                yAxisID: 'y_right',
            },
        ],
    };

    const options: Record<string, any> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxHeight: 0,
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y_left: {
                id: 'y_left',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: `${yLeftLabel} [${yLeftUnit}]`,
                },
                grid: {
                    display: false,
                    color: theme.palette.grey[300],
                },
            },
            y_right: {
                id: 'y_right',
                display: true,
                position: 'right',
                title: {
                    display: true,
                    text: `${yRightLabel} [${yRightUnit}]`,
                },
                grid: {
                    display: false,
                    color: theme.palette.grey[300],
                },
            },
        },
    };

    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {`表示期間: ${from} ~ ${to}`}
                </Typography>
                <Spacer mb={6} />

                <ChartWrapper>
                    <Line data={data} options={options} />
                </ChartWrapper>
            </CardContent>
        </Card>
    );
};

export default withTheme(summaryHourlyChart);
