import React from 'react';
import type { ReactElement } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import { Grid, Divider as MuiDivider, Typography as MuiTypography, Stack } from '@mui/material';
import { spacing } from '@mui/system';
import SummaryLayout from 'layouts/Summary';
import SummaryMonthlyChart from 'components/pages/summary/SummaryMonthlyChart';
import SummaryHourlyChart from 'components/pages/summary/SummaryHourlyChart';
import SummaryFunduseChart from 'components/pages/summary/SummaryFunduseChart';
import SummaryStats from 'components/pages/summary/SummaryStats';
import SummaryMap from 'components/pages/summary/SummaryMap';
import { BondSelect } from 'components/pages/BondSelect';
import { IssuerSelect } from 'components/pages/IssuerSelect';
import { useSetAtom, useAtomValue } from 'jotai';
import {
    fromDaySummaryAtom,
    toDaySummaryAtom,
    fromMonthSummaryAtom,
    toMonthSummaryAtom,
    issuerAtom,
    greenBondIdAtom,
} from '../jotai/Atoms';
import dayjs from 'dayjs';
import SummaryText from 'components/pages/summary/SummaryText';
import { getGreenBondConfig } from 'greenBondConfig';
import SummaryBondOverview from 'components/pages/summary/SummaryBondOverview';

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Default() {
    const date = new Date();
    const setFromDaySummary = useSetAtom(fromDaySummaryAtom);
    const seToDaySummary = useSetAtom(toDaySummaryAtom);
    const setFromMonthSummary = useSetAtom(fromMonthSummaryAtom);
    const setToMonthSummary = useSetAtom(toMonthSummaryAtom);
    const issuer = useAtomValue(issuerAtom);
    const greenBondId = useAtomValue(greenBondIdAtom);
    const config = getGreenBondConfig(greenBondId);

    seToDaySummary(dayjs(date).format('YYYY-MM-DD_HH'));
    setFromDaySummary(dayjs(date.setDate(date.getDate() - 1)).format('YYYY-MM-DD_HH'));
    setToMonthSummary(dayjs(date).format('YYYY-MM'));
    setFromMonthSummary(dayjs(date.setMonth(date.getMonth() - 12)).format('YYYY-MM'));

    return (
        <React.Fragment>
            <Helmet title="summary" />
            <Grid container>
                <Grid item>
                    <Typography variant="h3" gutterBottom>
                        サマリページ
                    </Typography>
                    <Typography variant="subtitle1">
                        {'GreenBondの各発行体及び発行GreenBond銘柄に応じたサマリ情報が閲覧できるページです。'}
                    </Typography>
                    <Typography variant="subtitle1" mt={2}>
                        {
                            'グリーン・デジタル・トラック・ボンドの発行体が、調達した資金使途の透明性を高めるため、資金を充当したプロジェクトの発電量やエネルギー消費量などのデータを自動的に計測し、CO2排出削減量などの環境改善効果に換算し、データ開示を行なっています。'
                        }
                    </Typography>
                </Grid>
            </Grid>

            <Divider my={6} />

            <Grid container spacing={6} mb={6}>
                <Grid item xs={12} lg={6}>
                    <IssuerSelect
                        placeholder="発行会社"
                        issuerList={[{ label: '株式会社日立製作所', value: '6501' }]}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <BondSelect placeholder="GreenBond名称" />
                </Grid>
            </Grid>

            {issuer && greenBondId ? (
                greenBondId == '0000000000000001' ? (
                    <>
                        <Grid container spacing={6}>
                            <Grid item xs={12} lg={3}>
                                <SummaryStats type="latest24h" value="first" />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <SummaryStats type="latest24h" value="second" />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <SummaryStats type="fromPublished" value="first" />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <SummaryStats type="fromPublished" value="second" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} lg={6}>
                                <SummaryHourlyChart />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <SummaryMonthlyChart />
                            </Grid>
                        </Grid>

                        <Grid container spacing={6}>
                            <Grid item xs={12} lg={7}>
                                <SummaryMap />
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Stack>
                                    <SummaryBondOverview />
                                    <SummaryFunduseChart />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container spacing={6}>
                            <Grid item xs={12} lg={12}>
                                <SummaryText topic="Note" textList={config.note} />
                            </Grid>
                        </Grid>
                    </>
                ) : greenBondId == '0000000000000007' ? (
                    <>
                        <Grid container spacing={6}>
                            <Grid item xs={12} lg={3}>
                                <SummaryStats type="latest24h" value="first" />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <SummaryStats type="latest24h" value="second" />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <SummaryStats type="fromPublished" value="first" />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <SummaryStats type="fromPublished" value="second" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} lg={6}>
                                <SummaryHourlyChart />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <SummaryMonthlyChart />
                            </Grid>
                        </Grid>

                        <Grid container spacing={6}>
                            <Grid item xs={12} lg={7}>
                                <SummaryMap />
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Stack spacing={6}>
                                    <SummaryBondOverview />
                                    <SummaryFunduseChart />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid container spacing={6}>
                            <Grid item xs={12} lg={12}>
                                <SummaryText topic="Note" textList={config.note} />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <></>
                )
            ) : (
                <></>
            )}
        </React.Fragment>
    );
}

Default.getLayout = function getLayout(page: ReactElement) {
    return <SummaryLayout>{page}</SummaryLayout>;
};

export default Default;
