import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { useMonthlyData } from 'hooks/useFetch';
import { CardContent, Card as MuiCard, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import { ThemeProps } from '../../../types/theme';
import { useAtomValue } from 'jotai';
import { fromMonthSummaryAtom, greenBondIdAtom, toMonthSummaryAtom } from '../../../jotai/Atoms';
import { getGreenBondConfig } from 'greenBondConfig';

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
    height: 325px;
    width: 100%;
`;

const summaryMonthlyChart = ({ theme }: ThemeProps) => {
    const from = useAtomValue(fromMonthSummaryAtom);
    const to = useAtomValue(toMonthSummaryAtom);
    const greenBondId = useAtomValue(greenBondIdAtom);
    const config = getGreenBondConfig(greenBondId);

    // Monthlyの値を取得
    const fetchdata = useMonthlyData(greenBondId, from, to);
    // 営業月（powerMonth）を取り出し
    const months: string[] = fetchdata['data']?.['powerMonth'];
    // 発電量（GeneratedGreenEnergy）を取り出し
    const firstValues: number[] = fetchdata['data']?.['GeneratedGreenEnergy'];
    // CO2削減量（powerMonth）を取り出し
    const secondValues: number[] = fetchdata['data']?.['AvoidedCO2'];

    const title = `${config.firstValueLabel} / ${config.secondValueLabel} (月次)`;
    const yLeftLabel = config.firstValueLabel;
    const yRightLabel = config.secondValueLabel;
    const yLeftUnit = config.firstValueUnit;
    const yRightUnit = config.secondValueUnit;
    const data = {
        // X軸に営業月を設定
        labels: months,
        // Y軸で表示するデータセットを定義
        datasets: [
            {
                label: yLeftLabel,
                backgroundColor: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
                hoverBackgroundColor: theme.palette.secondary.main,
                hoverBorderColor: theme.palette.secondary.main,
                data: firstValues,
                barPercentage: 0.75,
                categoryPercentage: 0.5,
                yAxisID: 'y_left',
            },
            {
                label: yRightLabel,
                backgroundColor: theme.palette.grey[300],
                borderColor: theme.palette.grey[300],
                hoverBackgroundColor: theme.palette.grey[300],
                hoverBorderColor: theme.palette.grey[300],
                data: secondValues,
                barPercentage: 0.75,
                categoryPercentage: 0.5,
                yAxisID: 'y_right',
            },
        ],
    };

    const options: Record<string, any> = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
            },
        },
        scales: {
            // Y軸（左）のスケールの表示定義
            y_left: {
                // id: 'y_left',
                position: 'left',
                title: {
                    display: true,
                    text: `${yLeftLabel} [${yLeftUnit}]`,
                },
                grid: {
                    display: false,
                },
                stacked: false,
                ticks: {
                    stepSize: 20,
                },
            },
            y_right: {
                position: 'right',
                title: {
                    display: true,
                    text: `${yRightLabel} [${yRightUnit}]`,
                },
                grid: {
                    display: false,
                },
                stacked: false,
                ticks: {
                    stepSize: 20,
                },
            },
            x: {
                stacked: false,
                grid: {
                    color: 'transparent',
                },
            },
        },
    };

    return (
        <Card mb={1}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <Spacer mb={6} />
                <ChartWrapper>
                    <Bar data={data} options={options} />
                </ChartWrapper>
            </CardContent>
        </Card>
    );
};

export default withTheme(summaryMonthlyChart);
