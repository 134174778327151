import dayjs from 'dayjs';
import { atom } from 'jotai';

// 日付の初期値設定のため
const toDate = dayjs(new Date());

/* summaryで使用するAtom*/
export const fromDaySummaryAtom = atom<string | undefined>('');
export const toDaySummaryAtom = atom<string | undefined>('');
export const fromMonthSummaryAtom = atom<string | undefined>('');
export const toMonthSummaryAtom = atom<string | undefined>('');

/* detailsページで使用するAtom*/
// 発行者情報を格納
export const issuerAtom = atom<string | undefined>('6501');
// greenBondIdを格納
export const greenBondIdAtom = atom<string | undefined>('0000000000000007');
// projectIdを格納
export const projectIdAtom = atom<string>('');
// 施設の名称とproject idを格納
export const facilityListAtom = atom<{ name: string; projectId: string }[]>([
    { name: '協創棟', projectId: '0000000000000007' },
]);
// 地図やFundUseグラフようの各施設の位置情報や説明を格納
export const facilityDetailListAtom = atom<any>(null);
// カスタム期間の選択有無を格納
export const customFlagAtom = atom(false);
// 日付データフォーマットを格納
export const formatDateAtom = atom('YYYY-MM-DD');
// 日付（データ期間のfrom)を格納
export const fromDateAtom = atom<dayjs.Dayjs | string | undefined>(toDate.subtract(7, 'd'));
// データフォーマット済日付(from)を格納
export const formattedFromDateAtom = atom(
    (get) => dayjs(get(fromDateAtom)).format(get(formatDateAtom)),
    (get, set, _arg: dayjs.Dayjs | undefined) => set(fromDateAtom, _arg)
);
// 日付（データ期間のto)を格納
export const toDateAtom = atom<dayjs.Dayjs | string | undefined>(toDate);
// データフォーマット済日付(to)を格納
export const formattedToDateAtom = atom(
    (get) => dayjs(get(toDateAtom)).format(get(formatDateAtom)),
    (get, set, _arg: dayjs.Dayjs | undefined) => set(toDateAtom, _arg)
);
// API実行結果データを格納
export const setApiDataAtom = atom<any>(undefined);
// 表示間隔単位（時間、日、月）格納
export const setDurationAtom = atom<string>('');
// テーブルに表示する施設名格納（テーブルデータ表示中に施設名称のセレクトボックスを選択しなおすと再レンダリングされ、表示中の施設情報のみが書きかわってしまうためSearchボタン押下時の施設名をjotaiで管理し、テーブルデータ表示に使用する）
export const setTableFacilityNameAtom = atom<string>('');
// 検索ボタン押下状態格納
export const setIsSearchButtonPressedAtom = atom<boolean>(false);
// ローディング状態格納
export const setIsLoadingAtom = atom<boolean>(false);
// チャートタイプ格納
export const chartTypeAtom = atom('bar');
