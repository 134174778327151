import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';

import { Card as MuiCard, CardContent as MuiCardContent, Typography as MuiTypography } from '@mui/material';
import { spacing } from '@mui/system';
import { useCumulativeData } from 'hooks/useFetch';
import { useAtomValue } from 'jotai';
import { fromDaySummaryAtom, greenBondIdAtom, toDaySummaryAtom } from '../../../jotai/Atoms';
import { getGreenBondConfig } from 'greenBondConfig';

const illustrationCardStyle = (props: any) => css`
    ${props.illustration &&
    props.theme.palette.mode !== 'dark' &&
    `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)<{ illustration?: string }>`
    position: relative;
    margin-bottom: ${(props) => props.theme.spacing(6)};

    ${illustrationCardStyle}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
    position: relative;

    &:last-child {
        padding-bottom: ${(props) => props.theme.spacing(4)};
    }
`;

const CardFigureStyle = styled(MuiTypography)`
    color: ${(props) => props.theme.palette.secondary.main};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const IllustrationImage = styled.img`
    height: 100px;
    position: absolute;
    right: ${(props) => props.theme.spacing(1)};
    bottom: ${(props) => props.theme.spacing(1)};
    display: none;

    ${(props) => props.theme.breakpoints.between('xs', 'lg')} {
        display: block;
    }

    @media (min-width: 1600px) {
        display: block;
    }
`;

interface StatsProps {
    type: 'latest24h' | 'fromPublished';
    value: 'first' | 'second';
    illustration?: string;
}

const SummaryStats = ({ type, value, illustration }: StatsProps) => {
    const greenBondId = useAtomValue(greenBondIdAtom);
    const config = getGreenBondConfig(greenBondId);
    const from = useAtomValue(fromDaySummaryAtom);
    const to = useAtomValue(toDaySummaryAtom);

    let title = '';
    let amount = '';
    let fetchdata = null;
    if (type == 'latest24h' && value == 'first') {
        fetchdata = useCumulativeData(greenBondId, from, to);
        title = `${config.firstValueLabel}（直近24時間）`;
        amount = `${fetchdata['data']?.['GeneratedGreenEnergy']} ${config.firstValueUnit}`;
    } else if (type == 'latest24h' && value == 'second') {
        fetchdata = useCumulativeData(greenBondId, from, to);
        title = `${config.secondValueLabel}（直近24時間）`;
        amount = `${fetchdata['data']?.['AvoidedCO2']} ${config.secondValueUnit}`;
    } else if (type == 'fromPublished' && value == 'first') {
        fetchdata = useCumulativeData(greenBondId, config.publishDate, to);
        title = `${config.firstValueLabel}（発行来）`;
        amount = `${fetchdata['data']?.['GeneratedGreenEnergy']} ${config.firstValueUnit}`;
    } else if (type == 'fromPublished' && value == 'second') {
        fetchdata = useCumulativeData(greenBondId, config.publishDate, to);
        title = `${config.secondValueLabel}（発行来）`;
        amount = `${fetchdata['data']?.['AvoidedCO2']} ${config.secondValueUnit}`;
    } else {
        fetchdata = useCumulativeData(greenBondId, config.publishDate, to);
        title = `${config.secondValueLabel}（発行来）`;
        amount = `${fetchdata['data']?.['AvoidedCO2']} ${config.secondValueUnit}`;
    }

    return (
        <Card illustration={illustration}>
            <CardContent>
                <Typography variant="h6" mb={4}>
                    {title}
                </Typography>
                <CardFigureStyle>
                    <Typography variant="h3" mb={3}>
                        {amount}
                    </Typography>
                </CardFigureStyle>
            </CardContent>
            {!!illustration && <IllustrationImage src={illustration} alt="Illustration" />}
        </Card>
    );
};

export default SummaryStats;
