interface GreenBondConfig {
    greenBondId: string;
    issuer: string;
    greenBondName: string;
    term: number;
    firstValueLabel: string;
    firstValueUnit: string;
    secondValueLabel: string;
    secondValueUnit: string;
    greenType: 'powerPlant' | 'greenBuilding';
    publishDate: string;
    note: string[];
    defaultChartType: 'line' | 'bar';
    mapWindowContents: string[];
    facilities: { name: string; projectId: string }[];
    tableHeader: {
        dateTime: string;
        facilityName: string;
        firstValue: string;
        secondValue: string;
    };
    cashIn: boolean;
    rate: string;
    redemptionDate?: string;
    issueDate: string;
    couponDate: string;
}

const defaultConfig: GreenBondConfig = {
    greenBondId: 'default',
    issuer: '',
    greenBondName: '',
    term: 0,
    firstValueLabel: '',
    firstValueUnit: '',
    secondValueLabel: '',
    secondValueUnit: '',
    greenType: 'greenBuilding',
    publishDate: '',
    note: [],
    defaultChartType: 'bar',
    mapWindowContents: [],
    facilities: [{ name: '', projectId: '' }],
    tableHeader: {
        dateTime: '',
        facilityName: '',
        firstValue: '',
        secondValue: '',
    },
    cashIn: true,
    rate: '0.0',
    issueDate: '',
    couponDate: '',
};

const greenBondConfigs: GreenBondConfig[] = [
    {
        greenBondId: '0000000000000001',
        issuer: '8697',
        greenBondName: '第１回無担保社債',
        term: 1,
        firstValueLabel: '発電量',
        firstValueUnit: 'kWh',
        secondValueLabel: 'C02排出削減量',
        secondValueUnit: 't-CO2',
        greenType: 'powerPlant',
        publishDate: '2022-07-01_00',
        note: [
            '・CO2削減量は、(各発電所における発電量) x (直近の東証ビルの排出量計算に用いた排出係数) で計算されます。',
            '・地図表示は正確な発電施設の所在地を表すものではありません。',
        ],
        defaultChartType: 'bar',
        mapWindowContents: [],
        facilities: [
            { name: '千葉県発電所', projectId: '0000000000000002' },
            { name: '茨城県発電所1', projectId: '0000000000000003' },
            { name: '茨城県発電所2', projectId: '0000000000000006' },
            { name: '茨城県発電所3', projectId: '0000000000000001' },
        ],
        tableHeader: {
            dateTime: '発電日時',
            facilityName: '施設名称',
            firstValue: '発電量 (kWh)',
            secondValue: 'CO2削減量 (t-CO2)',
        },
        cashIn: true,
        rate: '0.050',
        issueDate: '2022年6月1日',
        couponDate: '2022年12月3日及び2023年6月3日',
    },
    {
        greenBondId: '0000000000000007',
        issuer: '6501',
        greenBondName: '無担保第21回社債(社債間限定同順位特約及び譲渡制限付)(グリーン・デジタル・トラック・ボンド)',
        term: 5,
        firstValueLabel: '対象建物自体のエネルギー削減量',
        firstValueUnit: 'MJ',
        secondValueLabel: '対象建物自体のCO2排出削減量',
        secondValueUnit: 't-CO2',
        greenType: 'greenBuilding',
        publishDate: '2023-06-01_00',
        note: [
            '開示指標の計算式について：',
            '協創棟の外部購入電力量(1時間値)データを取得した後、以下の計算式に基づき、CO2排出削減量、エネルギー削減量の1時間値を計算しています。また、日次・月次・年次に集計しています。',
            '・「建物自体のCO2排出削減量」の1時間値(t-CO2)　＝　①「基準CO2排出量」の1時間値 – ③「実績CO2排出量」の1時間値',
            '・「建物自体のエネルギー削減量」の1時間値(MJ)　＝　②「基準エネルギー消費量」の1時間値 – ④「実績エネルギ―消費量」の1時間',
            '　①「基準CO2排出量」の1時間値(t-CO2) = 対象物件の延床面積(㎡) × 「DECCで設定されている延べ床面積当たりの一次エネルギー(MJ/㎡)」の1時間値(※)　×　1/1000(GJ/MJ)　×　原油の排出係数(tC/GJ)　×　44/12(t-CO2/tC)',
            '　②「基準エネルギー消費量」の1時間値(MJ) = 対象物件の延床面積(㎡) × 「DECCで設定されている延べ床面積当たりの一次エネルギー(MJ/㎡)」の1時間値(※)',
            '　　※「DECCで設定されている延べ床面積当たりの一次エネルギー」の1時間値は、一般社団法人 日本サステナブル建築協会が公開していますDECC(Data-base for Energy Consumption of Commercial buildings)の',
            '　　　個別情報データを集計して月次を求め、月次の値を等分することで算出しています。',
            '　　　従いまして、「基準CO2排出量」、「基準エネルギー消費量」の1時間値、日次は一定値になり、業務/非業務時間帯、業務/非業務日によるエネルギー需要の変動を考慮しておりません。',
            '　③「実績CO2排出量」の1時間値(t-CO2) = 「協創棟における外部購入電力」の1時間値(kWh) × 外部購入電力の排出係数(t-CO2/kWh)',
            '　④「実績エネルギー消費量」の1時間値(MJ) = 「協創棟における外部購入電力」の1時間値(kWh) × 電気の一次エネルギー換算係数(MJ/kWh)',
            '',
            'カーボン・オフセットについて:',
            '協創棟では、非化石証書によりオフセットを行うことで、再生可能エネルギー由来の電力100%使用を実現しています(詳細は以下のサイトをご参考ください)。上記の削減量は、オフセット前のエネルギー消費量から算出しています。',
            'https://www.powered-by-re.com/kyosoto/chart.html',
        ],
        defaultChartType: 'line',
        mapWindowContents: [],
        facilities: [{ name: '協創棟', projectId: '0000000000000007' }],
        tableHeader: {
            dateTime: '日時',
            facilityName: '施設名称',
            firstValue: '対象建物自体のエネルギー削減量 (MJ)',
            secondValue: '対象建物自体のCO2排出削減量 (t-CO2)',
        },
        cashIn: false,
        rate: '0.598',
        redemptionDate: '2028年12月14日',
        issueDate: '2023年12月14日',
        couponDate: '6月14日及び12月14日',
    },
];

export const getGreenBondConfig = (greenBondId: string | undefined) => {
    return greenBondConfigs.find((config) => config.greenBondId == greenBondId) ?? defaultConfig;
};

export const getGreenBonds = (issuerCode: string | undefined) => {
    return greenBondConfigs.filter((config) => config.issuer == issuerCode) ?? [defaultConfig];
};
