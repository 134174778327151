import React from 'react';
import styled from '@emotion/styled';

import { Card as MuiCard, CardContent, Typography } from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

interface TextProps {
    topic: string;
    textList: string[];
}

/**
 * 項目名とテキストをリスト順に表示する
 * @param 表示する項目名、テキストのリスト
 * @returns 項目名とテキストのコンポーネント
 */
const SummaryText = ({ topic, textList = [] }: TextProps) => {
    return (
        <Card mt={6} mb={6}>
            <CardContent sx={{ overflowX: 'auto' }}>
                <Typography variant="h6" gutterBottom>
                    {topic}
                </Typography>
                {textList.map((text, index) => {
                    return (
                        <Typography
                            sx={{ whiteSpace: 'nowrap', maxWidth: 'md' }}
                            key={index}
                            variant="body2"
                            gutterBottom
                        >
                            {text}
                        </Typography>
                    );
                })}
            </CardContent>
        </Card>
    );
};

export default SummaryText;
