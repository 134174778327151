import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableHead,
    TableRow as MuiTableRow,
} from '@mui/material';
import { spacing } from '@mui/system';
import { useAtomValue } from 'jotai';
import { greenBondIdAtom } from '../../../jotai/Atoms';
import { getGreenBondConfig } from 'greenBondConfig';

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
    &:last-child {
        padding-bottom: ${(props) => props.theme.spacing(2)};
    }
`;

const TableRow = styled(MuiTableRow)`
    height: 30px;

    &:last-child th,
    &:last-child td {
        border-bottom: 0;
    }
`;

const TableCell = styled(MuiTableCell)`
    padding-top: 0;
    padding-bottom: 0;
`;

const SummaryBondOverview = () => {
    // APIから取得した各発電所データ
    const greenBondId = useAtomValue(greenBondIdAtom);
    const config = getGreenBondConfig(greenBondId);

    return (
        <Card>
            <CardHeader title="社債概要" sx={{ pt: 4, pb: 0 }} />
            <CardContent sx={{ pt: 2, pb: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">発行日</TableCell>
                            <TableCell align="left">年限</TableCell>
                            <TableCell align="left">利率</TableCell>
                            <TableCell align="left">利払日</TableCell>
                            <TableCell align="left">償還期日</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">{config.issueDate}</TableCell>
                            <TableCell align="left">{config.term}年債</TableCell>
                            <TableCell align="left">{config.rate}%</TableCell>
                            <TableCell align="left">{config.couponDate}</TableCell>
                            <TableCell align="left">{config.redemptionDate}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default withTheme(SummaryBondOverview);
