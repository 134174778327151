import useSWR from 'swr';

const fetcher = (url: string) =>
    fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((r) => r.json());

export const useMonthlyData = (greenbondid: string | undefined, from: string | undefined, to: string | undefined) => {
    return useSWR(
        greenbondid && from
            ? `${process.env.NEXT_PUBLIC_API_ENDPOINT}/get_greenbondid_monthlylambda?greenBondId=${greenbondid}&From=${from}&To=${to}`
            : null,
        fetcher
    );
};

export const useHourlyData = (greenbondid: string | undefined, from: string | undefined, to: string | undefined) => {
    return useSWR(
        greenbondid && from
            ? `${process.env.NEXT_PUBLIC_API_ENDPOINT}/get_greenbondid_hourlylambda?greenBondId=${greenbondid}&From=${from}&To=${to}`
            : null,
        fetcher
    );
};

export const useCumulativeData = (
    greenbondid: string | undefined,
    from: string | undefined,
    to: string | undefined
) => {
    return useSWR(
        greenbondid && from
            ? `${process.env.NEXT_PUBLIC_API_ENDPOINT}/get_greenbondid_cumulativelambda?greenBondId=${greenbondid}&From=${from}&To=${to}`
            : null,
        fetcher
    );
};

export const useFacilityData = (greenbondid: string | undefined) => {
    const { data, error } = useSWR(
        greenbondid ? `${process.env.NEXT_PUBLIC_API_ENDPOINT}/get_summarymaplambda?greenBondId=${greenbondid}` : null,
        fetcher
    );
    if (error) {
        console.error(error);
    }
    return data && data.length > 0 ? data : null;
};
