import React, { useState } from 'react';
import styled from '@emotion/styled';
import GoogleMapReact, { Maps } from 'google-map-react';
import { withTheme } from '@emotion/react';
import { CardContent, Card as MuiCard, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import { ThemeProps } from '../../../types/theme';
import { useAtom, useAtomValue } from 'jotai';
import { facilityDetailListAtom, greenBondIdAtom } from '../../../jotai/Atoms';
import { getGreenBondConfig } from 'greenBondConfig';
import { useFacilityData } from 'hooks/useFetch';

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const GoogleMapReactWrapper = styled.div`
    height: 412px;
    width: 100%;
`;

const SummaryMap = ({ theme }: ThemeProps) => {
    // 初期位置（初期表示用）
    const [options, setOptions] = useState<any>({
        center: {
            lat: 36.10383731532607,
            lng: 139.90654843910957,
        },
        zoom: 14,
    });

    const greenBondId = useAtomValue(greenBondIdAtom);
    const config = getGreenBondConfig(greenBondId);

    const [fetchdata, setFetchData] = useAtom(facilityDetailListAtom);
    setFetchData(useFacilityData(greenBondId));

    const getMapOptions = (maps: Maps) => {
        return {
            fullscreenControl: true,
            mapTypeControl: false,
            mapTypeId: maps.MapTypeId.ROADMAP,
            scaleControl: false,
            scrollwheel: false,
            streetViewControl: false,
            styles: [
                {
                    // 国や都道府県名や市区町村などの大きな領域のラベルのテキスト
                    featureType: 'administrative',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                        {
                            color: theme.palette.secondary.main,
                        },
                    ],
                },
                {
                    // 地形
                    featureType: 'landscape',
                    elementType: 'geometry',
                    stylers: [
                        {
                            // 基本色を指定
                            hue: theme.palette.secondary.main,
                        },
                        {
                            // 彩度の変化率
                            saturation: '-70',
                        },
                        {
                            // 明度の変化率
                            lightness: '0',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    elementType: 'labels',
                    stylers: [
                        {
                            hue: theme.palette.secondary.main,
                        },
                        {
                            saturation: '-80',
                        },
                        {
                            lightness: '-90',
                        },
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    // 「Points of interest」の略。観光スポットや施設の名前、学校、公園など
                    featureType: 'poi',
                    elementType: 'all',
                    stylers: [
                        {
                            hue: theme.palette.secondary.main,
                        },
                        {
                            saturation: '-80',
                        },
                        {
                            lightness: '-70',
                        },
                        {
                            visibility: 'off',
                        },
                        {
                            gamma: '1',
                        },
                    ],
                },
                {
                    // 道路
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [
                        {
                            hue: theme.palette.secondary.main,
                        },
                        {
                            saturation: '-85',
                        },
                        {
                            lightness: '60',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'labels',
                    stylers: [
                        {
                            hue: theme.palette.secondary.main,
                        },
                        {
                            saturation: '-70',
                        },
                        {
                            lightness: '50',
                        },
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'road.local',
                    elementType: 'all',
                    stylers: [
                        {
                            hue: theme.palette.secondary.main,
                        },
                        {
                            saturation: '0',
                        },
                        {
                            lightness: '-11',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    // 公共交通機関。電車の路線や駅、バス停、空港などを含む
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [
                        {
                            visibility: 'simplified',
                        },
                        {
                            hue: theme.palette.secondary.main,
                        },
                        {
                            lightness: '0',
                        },
                        {
                            saturation: '0',
                        },
                    ],
                },
                {
                    // 路線図
                    featureType: 'transit',
                    elementType: 'labels',
                    stylers: [
                        {
                            hue: theme.palette.secondary.main,
                        },
                        {
                            lightness: -100,
                        },
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    // 海洋の色
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [
                        {
                            hue: '#0066ff',
                        },
                        {
                            saturation: '0',
                        },
                        {
                            lightness: 100,
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    // 海洋名称の色
                    featureType: 'water',
                    elementType: 'labels',
                    stylers: [
                        {
                            hue: '#000000',
                        },
                        {
                            saturation: -100,
                        },
                        {
                            lightness: -100,
                        },
                        {
                            visibility: 'off',
                        },
                    ],
                },
            ],
        };
    };

    const renderMap = (map: any, maps: any) => {
        // boundsにgoogleMapの境界情報を持たせておく
        const bounds = new maps.LatLngBounds();
        // 中央値算出用の変数
        let latsum = 0;
        let lngsum = 0;

        // APIから取得した情報からMapに表示するデータを設定
        if (fetchdata) {
            fetchdata.forEach((item: any) => {
                let latnum = 0;
                let lngnum = 0;
                latnum = Number(item.lat);
                lngnum = Number(item.lng);

                latsum = latsum + latnum;
                lngsum = lngsum + lngnum;

                const marker = new maps.Marker({
                    position: {
                        lat: latnum,
                        lng: lngnum,
                    },
                    map,
                    title: item.title,
                    icon: {
                        fillColor: theme.palette.secondary.main,
                        fillOpacity: 1,
                        path: maps.SymbolPath.CIRCLE,
                        scale: 9,
                        strokeColor: theme.palette.secondary.main,
                        strokeWidth: 7,
                        strokeOpacity: 0.4,
                    },
                });

                const card =
                    config.greenType == 'powerPlant'
                        ? `<b>${item.facilityName}(${item.powerCategory})</b><br><br>プロジェクト概要：${item.projectOverview}<br>敷地面積：${item.siteAria}<br>設備容量：${item.capacity}`
                        : config.greenType == 'greenBuilding'
                        ? `<b>${item.facilityName}</b><br><br>プロジェクト概要：${item.projectOverview}<br>延床面積：${item.siteAria}`
                        : '';
                const infowindow = new maps.InfoWindow({
                    content: card,
                });

                // mouseoverされたときに情報を表示
                marker.addListener('mouseover', () => {
                    infowindow.open({
                        anchor: marker,
                        map,
                        shouldFocus: false,
                    });
                });
                // mouseoutされたときに情報を非表示
                marker.addListener('mouseout', () => {
                    infowindow.close();
                });

                // 境界情報に対して、新たな位置座標を追加
                bounds.extend(marker.position);
            });
            // 境界の情報に応じて、googleMapの表示が適切になるように、位置座標、倍率を調整
            // 自動調整で、不自然に拡大してしまう場合があるので、自動調整後のzoom値の最大値を固定する
            map.fitBounds(bounds);
            map.setZoom(map.getZoom() < 10 ? map.getZoom() : 10);

            // Mapの中央値を算出
            const centerlat = latsum / fetchdata.length;
            const centerlng = lngsum / fetchdata.length;
            // 初期位置を設定
            const options = {
                center: {
                    lat: centerlat,
                    lng: centerlng,
                },
                zoom: 14,
            };
            setOptions(options);
        }
    };

    return (
        <Card sx={{ height: '100%' }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    施設情報
                </Typography>

                <Spacer mb={6} />

                <GoogleMapReactWrapper>
                    {fetchdata && (
                        <GoogleMapReact
                            key={fetchdata}
                            options={getMapOptions}
                            bootstrapURLKeys={{
                                key: fetchdata[0]['google_apikey'],
                            }}
                            center={options.center}
                            zoom={options.zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => renderMap(map, maps)}
                        />
                    )}
                </GoogleMapReactWrapper>
            </CardContent>
        </Card>
    );
};

export default withTheme(SummaryMap);
