import React from 'react';
import styled from '@emotion/styled';
import { useAtom, useSetAtom } from 'jotai';
import {
    facilityListAtom,
    greenBondIdAtom,
    issuerAtom,
    projectIdAtom,
    setApiDataAtom,
    setIsSearchButtonPressedAtom,
} from '../../jotai/Atoms';

import { MenuItem, FormControl as MuiFormControl, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { spacing, sizing, palette } from '@mui/system';
import { getGreenBondConfig, getGreenBonds } from 'greenBondConfig';

const FormControlSizing = styled(MuiFormControl)(sizing, spacing, palette);

const FormControl = styled(FormControlSizing)`
    width: 100%;
    background: ${(props) => props.theme.palette.background.paper};
`;

interface SelectProps {
    placeholder: string;
    issuerList: { label: string; value: string }[];
}

export const IssuerSelect = ({ placeholder, issuerList }: SelectProps) => {
    const [issuer, setIssuer] = useAtom(issuerAtom);
    const [greenBondId, setBond] = useAtom(greenBondIdAtom);
    const setIsSearchButtonPressed = useSetAtom(setIsSearchButtonPressedAtom);
    const setApiData = useSetAtom(setApiDataAtom);
    const setProjectId = useSetAtom(projectIdAtom);
    const setFacilityList = useSetAtom(facilityListAtom);

    if (issuerList.filter((item: { label: string; value: string }) => item.value === issuer).length === 0) {
        setIssuer(issuerList[0].value);
    }

    const handleChange = (event: SelectChangeEvent) => {
        const greenBonds = getGreenBonds(event.target.value);
        // 現在表示されているボンドが、検索対象の発行体のボンドに含まれていない場合
        // 検索対象の発行体の一番上のボンドを表示する
        if (greenBonds.filter((config) => config.greenBondId == greenBondId).length == 0) {
            setBond(greenBonds[0].greenBondId);
        }
        setIssuer(event.target.value);
        setProjectId('');
        setFacilityList(getGreenBondConfig(greenBonds[0].greenBondId).facilities);
        setIsSearchButtonPressed(false);
        setApiData(undefined);
    };

    return (
        <FormControl>
            <Select value={issuer} onChange={handleChange} displayEmpty>
                <MenuItem value="" disabled>
                    {placeholder}
                </MenuItem>
                {issuerList.map((item) => {
                    return (
                        <MenuItem key={item.label} value={item.value}>
                            {item.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
