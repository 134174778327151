import React from 'react';
import styled from '@emotion/styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
    facilityListAtom,
    greenBondIdAtom,
    issuerAtom,
    projectIdAtom,
    setApiDataAtom,
    setIsSearchButtonPressedAtom,
} from '../../jotai/Atoms';

import { MenuItem, FormControl as MuiFormControl, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { spacing, sizing, palette } from '@mui/system';
import { getGreenBondConfig, getGreenBonds } from 'greenBondConfig';

const FormControlSizing = styled(MuiFormControl)(sizing, spacing, palette);

const FormControl = styled(FormControlSizing)`
    width: 100%;
    background: ${(props) => props.theme.palette.background.paper};
`;

interface SelectProps {
    placeholder: string;
}

export const BondSelect = ({ placeholder }: SelectProps) => {
    const [greenBondId, setBond] = useAtom(greenBondIdAtom);
    const issuer = useAtomValue(issuerAtom);
    const bondList = getGreenBonds(issuer);
    const setIsSearchButtonPressed = useSetAtom(setIsSearchButtonPressedAtom);
    const setApiData = useSetAtom(setApiDataAtom);
    const setProjectId = useSetAtom(projectIdAtom);
    const setFacilityList = useSetAtom(facilityListAtom);

    const handleChange = (event: SelectChangeEvent) => {
        setBond(event.target.value);
        setIsSearchButtonPressed(false);
        setProjectId('');
        setFacilityList(getGreenBondConfig(event.target.value).facilities);
        setApiData(undefined);
    };

    if (bondList.filter((item) => item.greenBondId === greenBondId).length === 0) {
        setBond(bondList[0].greenBondId);
    }

    return (
        <FormControl>
            <Select value={greenBondId} onChange={handleChange} displayEmpty>
                <MenuItem value="" disabled>
                    {placeholder}
                </MenuItem>
                {bondList.map((item) => {
                    return (
                        <MenuItem key={item.greenBondName} value={item.greenBondId}>
                            {item.cashIn ? '（償還済み）' + item.greenBondName : item.greenBondName}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
